import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import paynitelogo from "../../assets/images/paynitelogo.png";
import { useOktaAuth } from "@okta/okta-react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_GATEWAY } from "../../Apigate/api";
import { useLocation } from "react-router-dom";

const PaymentLinks = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [verifytok, setVerifytok] = useState(null);
  const [merchantData, setMerchantData] = useState([]);
  const [shortLink, setShortLink] = useState("");
  const [formData, setFormData] = useState({
    amount: "",
  });
  // console.log("formdata--1111", formData);
  const { merchantId } = useParams();
  const [showPopUp, setShowPopUp] = useState(false); 
  const [uniqueKey, setUniqueKey] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const planName = searchParams.get("plan");
  //-------


  // const [amount, setAmount] = useState("8708832520");
  //-------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    // console.log("111111111111", merchantData);
    if (merchantData && merchantData.merchantOktaId) {
      setFormData((prevData) => ({
        ...prevData,
        merchantOktaId: merchantData.merchantOktaId,
        merchantId: merchantData.merchantId,
        adminEmailId: "payfiadmin@payfi.co.in",
        targetAccountId: 87399435,
        targetOwnerName: "Payfi",
        amount: "",
        currency: "INR",
        message: "payment for goods",
        latitude: 12.12,
        longitude: 13.74,
        partnerReferenceNumber: 34788441,
        customerVpa: "",
        status: "pending",
        initiationDate: "2024-04-19T10:15:30",
        completionDate: "2024-04-20T10:15:30",
      }));
    }
  }, [merchantData]); 

  useEffect(() => {
    if (authState && authState.accessToken) {
      const { accessToken } = authState.accessToken;
      setVerifytok(accessToken);
    }
  });

  const fetchMerchantDetails = async () => {
    try {
      const response = await axios.get(
        API_GATEWAY + "merchants/getalldetailsofmerchant",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${verifytok}`,
          },
        }
      );

      const data = response;
      setMerchantData(data.data.merchantBasicDetails);
      // console.log("response", merchantData.merchantId);
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  useEffect(() => {
    if (verifytok) {
      fetchMerchantDetails();
    }
  }, [verifytok, merchantId]);

  useEffect(() => {
    if (verifytok) {
      fetchMerchantDetails();
    }
  }, [verifytok, merchantId]);

  const generatePaymentLink = async () => {
    try {
      const apiUrl = `${API_GATEWAY}transactions/upilinkgeneration`; // Replace with your API URL

      const recurringPaymentDetails = {
        ...formData,
      };
      console.log("recurringPaymentDetails", recurringPaymentDetails);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${verifytok}`,
        },
        body: JSON.stringify(recurringPaymentDetails),
      });
      console.log("formdata", formData);
      if (response.ok) {
        const responseData = await response.text();
        // const responseData = await response;
        setShowPopUp(true); // Show the pop-up when the link is generated

        setShortLink(responseData);
        setUniqueKey(extractUniqueKey(responseData));
        console.log(responseData);
        // console.log("responseData",responseData)
      } else {
        const errorData = await response.json();
        console.error("Error subscribing recurring plan:", errorData);
        toast.error("Error subscribing recurring plan", {
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function handlePaymethodAmound(e) {
    e.preventDefault();
    // navigate('/payment-LinkEmail',{ state: formData.amount })
    // console.log(formData.amount);
            navigate("/payment-LinkEmail", { state: { shortLink: shortLink, uniqueKey: uniqueKey } });

  }
  // ------------------------fdfdfdfdfdfdfdfdfdf
  const extractUniqueKey = (url) => {
    if (url) {
      const paramsStartIndex = url.indexOf("?");
      if (paramsStartIndex !== -1) {
        const paramsString = url.substring(paramsStartIndex + 1);
        const paramsArray = paramsString.split("&");
        for (const param of paramsArray) {
          const [key, value] = param.split("=");
          if (key === "uniqueKey") {
            return value;
          }
        }
      }
    }
    return null;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shortLink).then(() => {
      toast.success("Link copied to clipboard!", {
        autoClose: 2000,
      });
    }).catch((error) => {
      console.error("Error copying text: ", error);
      toast.error("Failed to copy link.", {
        autoClose: 2000,
      });
    });
  };
  return (
    <>
      <Navbar />
      <div className="flex justify-center">
        <h1 className="text-3xl text-[#6a5acd] font-bold pt-6 -mb-2">
        Create Payment Link
        </h1>
        <ToastContainer />
      </div>
      <div className="flex justify-center">
      <div className="mt-7">
        <div className="flex justify-between border p-6 font-bold">
          <p className="mt-2">Amount</p>
          <div className=" p-1 font-bold">
            <input
              type="text"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              className="px-3 w-full py-1 ml-2 mb-1 border border-black rounded appearance-none"
            />
          </div>
        </div>
      </div>
        <div className="p-4">
          <button
            type="submit"
            className="bg-[#6A5ACD] text-white py-2 px-4 rounded mt-7"
            onClick={generatePaymentLink}
          >
            Generate Payment Link
          </button>
         

          {showPopUp && (
           <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
             <div className="bg-white rounded-lg p-16 max-w-5xl">
                <p className="text-lg font-bold mb-4">Quick URL Link</p>
                <a
                  href={shortLink}
                  onClick={handlePaymethodAmound}
                  className="text-blue-500 hover:underline"
                >
                  {shortLink}
                </a>
                <div> 
                <button
                    className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-2 px-2 rounded mt-2"
                    onClick={copyToClipboard}
                  >
                    Copy
                  </button>
                  <button
                    className="mt-6 bg-gray-500  p-21 hover:bg-gray-600 text-white px-6 py-2 rounded"
                    onClick={() => setShowPopUp(false)}
                  >
                    Close
                  </button>
                  
             
                </div>
              </div>
              
            </div>
          )}
       
        </div>
      
      </div>
      <div className="flex justify-center items-center mt-3">
  <div className="bg-gray-100 rounded-lg p-8">
  <h1 className="text-xl font-bold mb-4 flex justify-center items-center">Merchant Information</h1>
    <div className="grid grid-cols-2 gap-y-4">
      <p className="text-lg font-bold">
        Merchant Name:
      </p>
      <p className="text-lg">
        {merchantData.merchantName}
      </p>
      <p className="text-lg font-bold">
        Email ID:
      </p>
      <p className="text-lg">
        {merchantData.emailId}
      </p>
      <p className="text-lg font-bold">
        Merchant Contact:
      </p>
      <p className="text-lg">
        {merchantData.merchantContact}
      </p>
    </div>
  </div>
</div>

    </>
  );
};

export default PaymentLinks;
