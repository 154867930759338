import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Navbar from "../screens/Navbar";
import { API_GATEWAY } from "../Apigate/api";


const ExtractEncodedPayload = ({ urllinkpayload }) => {
  const [encodedPayload, setEncodedPayload] = useState("");
  const [uniquekeyPayload, setUniquePayload] = useState("");
  const [jwt, setJwt] = useState("");
  const [customerVpa,setCustomerVpa] = useState("")
  const [paymentCustData,setPaymentCustData] = useState("")
  const navigate = useNavigate();


  useEffect(() => {
    const url = new URL(urllinkpayload);

    const params = new URLSearchParams(url.search);
    const jwt = params.get("jwt");
    const encodedPayload = params.get("encodedPayload");
    const uniqueKeypayload = params.get("uniqueKey");
    setEncodedPayload(encodedPayload);
    setUniquePayload(uniqueKeypayload);
    setJwt(jwt);
    console.log(encodedPayload)
  }, []);



  
  // const decodeJWT = (encodedPayload) => {
  //   const base64Url = encodedPayload.split('.')[1]; // Get the payload part of the JWT
  //   const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  //   const jsonPayload = decodeURIComponent(
  //     atob(base64)
  //       .split('')
  //       .map(function (c) {
  //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  //       })
  //       .join('')
  //   );
  //   return JSON.parse(jsonPayload);
  // };
  // console.log("decodeJWT",decodeJWT(encodedPayload))
  

    const handleCustomerVpa = async () => {
        try {
          // const paymentlinkthrough = await axios.post(`http://localhost:8083/transactions/paythroughlink?jwt=${encodeURIComponent(jwt)}&encodedPayload=${encodeURIComponent(encodedPayload)}&uniqueKey=${uniquekeyPayload}&customerVpa=${customerVpa}`, {},
          const paymentlinkthrough = await axios.post(API_GATEWAY + `transactions/paythroughlink?jwt=${encodeURIComponent(jwt)}&encodedPayload=${encodeURIComponent(encodedPayload)}&uniqueKey=${uniquekeyPayload}&customerVpa=${customerVpa}`, {},

          {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${encodeURIComponent(jwt)}`
            }
          });
          setPaymentCustData(paymentlinkthrough.data)
          // console.log("paymentlinkthrough",paymentCustData);
          navigate("/payment-status", { state: { paymentCustData: paymentlinkthrough.data } });
        } catch (error) {
          console.error('Error fetching data: ', error);
      }
    }

    return (
      <div className=" bg-gray-100">
        <Navbar />
        <div className="flex justify-center items-center h-screen bg-gray-100">
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
            <h1 className="text-3xl font-bold mb-6 text-center">SecurePay</h1>
            <div className="space-y-4">
              <label className="block font-semibold" htmlFor="customerVpa">
                Customer VPA:
              </label>
              <input
                className="block w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500"
                type="text"
                id="customerVpa"
                placeholder="Enter customer VPA"
                value={customerVpa}
                onChange={(e) => setCustomerVpa(e.target.value)}
              />
            </div>
            <button
              onClick={handleCustomerVpa}
              className="mt-6 w-full bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>
    );
};

export default ExtractEncodedPayload;
