import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import paynitelogo from "../../assets/images/paynitelogo.png";
import { useOktaAuth } from "@okta/okta-react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_GATEWAY } from "../../Apigate/api";
import { useLocation } from "react-router-dom";

const RequestTransaction = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [verifytok, setVerifytok] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [customerEmailId, setCustomerEmailId] = useState("");
  const [customerMobileNumber, setCustomerMobileNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [merchantData, setMerchantData] = useState([]);
  const [businessdetails, setBusinessDetails] = useState([]);
  const { merchantId } = useParams();
  const [frequency, setFrequency] = useState("");
  const [installmentAmount, setInstallmentAmount] = useState("");
  const [autodebit, setAutodebit] = useState("true");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // State to manage confirmation modal

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const planName = searchParams.get("plan");

  useEffect(() => {
    if (authState && authState.accessToken) {
      const { accessToken } = authState.accessToken;
      setVerifytok(accessToken);
    } else {
    }
  });

  const handleCustomerName = (event) => {
    const inputCustomerName = event.target.value;
    setCustomerName(inputCustomerName);
  };

  const handleCustomerMobileNumber = (event) => {
    const inputCustomerMobileNumber = event.target.value;
    setCustomerMobileNumber(inputCustomerMobileNumber);
  };

  const handleCustomerEmailId = (event) => {
    const inputCustomerEmailId = event.target.value;
    setCustomerEmailId(inputCustomerEmailId);
  };

  const handleAmount = (event) => {
    const inputAmount = event.target.value;
    setAmount(inputAmount);
  };

  const fetchMerchantDetails = async () => {
    try {
      const response = await axios.get(
        API_GATEWAY + "merchants/getalldetailsofmerchant",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${verifytok}`,
          },
        }
      );

      const data = response;
      setMerchantData(data.data.merchantBasicDetails);
      setBusinessDetails(data.data.merchantBusinessDetails);
      console.log("response", merchantData.merchantId);
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  useEffect(() => {
    if (verifytok) {
      fetchMerchantDetails();
    }
  }, [verifytok, merchantId]);

  const generatePaymentLink = async () => {
    try {
      const apiUrl = ""; // Replace with your API URL

      const paymentDetails = {
        merchantOktaId: merchantData.merchantOktaId,
        merchantId: merchantData.merchantId,
        customerName: customerName,
        customerEmailId: customerEmailId,
        customerMobileNumber: customerMobileNumber,
        amount: amount,
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${verifytok}`,
        },
        body: JSON.stringify(paymentDetails),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Recurring Plan subscribed successfully:", responseData);
        toast.success("Recurring Plan subscribed successfully", {
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        const errorData = await response.json();
        console.error("Error subscribing recurring plan:", errorData);
        toast.error("Error subscribing recurring plan", {
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex justify-center">
        <h1 className="text-3xl text-[#6a5acd] font-bold pt-2 -mb-2">
          Request Transaction
        </h1>
        <ToastContainer />
      </div>
      {/* <div className="flex flex-col">
        <div className="flex py-6">
          <table className="w-1/2 m-4 border border-black border-collapse">
            <thead>
              <tr>
                <th className="text-2xl border px-2 py-4 font-bold" colSpan="2">
                  Enter Customer Info
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border p-1 font-bold">Customer Name</td>
                <td className="border p-1 font-bold">
                  <input
                    type="text"
                    value={customerName}
                    onChange={handleCustomerName}
                    className={`px-3 w-full py-1 mb-1 border border-black rounded appearance-none`}
                  />
                </td>
              </tr>
              <tr>
                <td className="border p-1 font-bold">Customer Mobile Number</td>
                <td className="border p-1 font-bold">
                  <input
                    type="text"
                    value={customerMobileNumber}
                    onChange={handleCustomerMobileNumber}
                    className={`px-3 w-full py-1 mb-1 border border-black rounded appearance-none`}
                  />
                </td>
              </tr>
              <tr>
                <td className="border p-1 font-bold">Customer Email ID</td>
                <td className="border p-1 font-bold">
                  <input
                    type="text"
                    value={customerEmailId}
                    onChange={handleCustomerEmailId}
                    className={`px-3 w-full py-1 mb-1 border border-black rounded appearance-none`}
                  />
                </td>
              </tr>
              <tr>
                <td className="border p-1 font-bold">Amount</td>
                <td className="border p-1 font-bold">
                  <input
                    type="text"
                    value={amount}
                    onChange={handleAmount}
                    className={`px-3 w-full py-1 mb-1 border border-black rounded appearance-none`}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex justify-center">
          <div className="p-4">
            <button
              type="submit"
              className="bg-[#6A5ACD] text-white py-2 px-4 rounded"
              onClick={generatePaymentLink}
            >
              PAY
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default RequestTransaction;
