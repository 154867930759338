// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-main-menu{
    /* background-color: dodgerblue; */
}


.hideMenuNav {
    display: none;
  }
  .showMenuNav {
    display: block;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: white;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
`, "",{"version":3,"sources":["webpack://./src/styles/navbar.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;AACtC;;;AAGA;IACI,aAAa;EACf;EACA;IACE,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,MAAM;IACN,OAAO;IACP,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;EACrB","sourcesContent":[".dashboard-main-menu{\n    /* background-color: dodgerblue; */\n}\n\n\n.hideMenuNav {\n    display: none;\n  }\n  .showMenuNav {\n    display: block;\n    position: absolute;\n    width: 100%;\n    height: 100vh;\n    top: 0;\n    left: 0;\n    background: white;\n    z-index: 10;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n    align-items: center;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
