import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import YearlyReport from "./YearlyReport";
import MonthlyReport from "./MonthlyReport";
import { format } from "date-fns";
import MerchantReports from "./MerchantReports";
import SellerSettleReports from "./SellerSettlementReports/SellerSettleReports";

const Reportcharting = () => {
  const { authState } = useOktaAuth();
  const [verifytok, setVerifytok] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [reportGetData, setReportGetData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [pageshow, setPageshow] = useState(false);
  const [merchantId, setMerchantId] = useState("");
  const [merchantdailydata, setMerchantIdDailyData] = useState("");

  const handleSectionChange = (section) => {
    setSelectedSection(section);
    setSelectedReport(null);
    setSelectedYear(null);
  };

  const handleReportChange = (report) => {
    setSelectedReport(report);
    setSelectedYear(null);
    setShowGraph(false); // Reset showGraph state when changing report type
    setPageshow(false);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  const handleStartDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedDate2(date);
  };

  const constructReportURL = (reportType) => {
    const baseUrl = "http://localhost:8089/report";
    const token = authState.accessToken.accessToken;
    let url = "";
    switch (reportType) {
      case "monthly":
        const startDate = selectedDate ? format(selectedDate, "yyyy-MM-dd") : null;
        const endDate = selectedDate2 ? format(selectedDate2, "yyyy-MM-dd") : null;
        if (!startDate || !endDate) {
          setError("Please select start and end dates");
          return;
        }
        url = `${baseUrl}/total-amount-between-dates-admin/${startDate}/${endDate}`;
        break;
      case "yearly":
        if (!selectedYear) {
          setError("Please select a year");
          return;
        }
        url = `${baseUrl}/total-amount-for-year-admin?year=${selectedYear}`;
        break;
      case "merchantDetails":
        const merchantDate = selectedDate ? format(selectedDate, "yyyy-MM-dd") : null;
        if (!merchantDate) {
          setError("Please select start date");
          return;
        }
        const merchantId = 1; // Replace with your logic to get merchantId
        url = `${baseUrl}/total-amount-by-merchantId-and-date-daily?merchantId=${encodeURIComponent(merchantId)}&date=${encodeURIComponent(merchantDate)}`;
        break;
      default:
        console.log("Invalid report type");
    }
    return { url, token };
  };

  const fetchReportData = async () => {
    setLoading(true);
    setError(null);

    const { url, token } = constructReportURL(selectedReport);
    if (!url || !token) return;

    try {
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setShowGraph(true);
        setReportData(response.data);
      } else {
        setError("Failed to fetch data");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authState && authState.accessToken) {
      const { accessToken } = authState.accessToken;
      setVerifytok(accessToken);
    }
  }, [authState]);


  return (
    <div>
      <select onChange={(e) => handleSectionChange(e.target.value)}>
        <option value="">Select Section</option>
        <option value="transaction">Transaction</option>
        <option value="recurring">Recurring</option>
        <option value="settlement">Seller Settlement</option>
      </select>
      {selectedSection && selectedSection === "transaction" && (
        <div>
          <select onChange={(e) => handleReportChange(e.target.value)}>
            <option value="">Select Report Type</option>
            <option value="daily">Daily</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
            <option value="merchantDetails">merchantDetails</option>
          </select>
          {selectedReport && (
            <div>
              {selectedReport !== "monthly" &&
                selectedReport !== "merchantDetails" && (
                  <div>
                    <label>Select Year:</label>
                    <select
                      value={selectedYear}
                      onChange={(e) => handleYearChange(e.target.value)}
                    >
                      <option value="">Select Year</option>
                      {Array.from(
                        { length: 10 },
                        (_, i) => new Date().getFullYear() - i
                      ).map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              {selectedReport === "monthly" && (
                <div>
                  <label>Select Start Date:</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => handleStartDateChange(date)}
                    dateFormat="yyyy-MM-dd"
                  />
                  <label>Select End Date:</label>
                  <DatePicker
                    selected={selectedDate2}
                    onChange={(date) => handleEndDateChange(date)}
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
              )}
              {selectedReport === "merchantDetails" &&
                selectedReport !== "monthly" &&
                selectedReport !== "yearly" && (
                  <div>
                    <p>Merchant id</p>
                    <input type="text" value={merchantId} 
                        onChange={(e) => setMerchantId(e.target.value)} // Add onChange handler to update the merchantId state
                      placeholder="filter merchant id" />
                    <label>Select merchant Date:</label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => handleStartDateChange(date)}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                )}

              <button
              style={{background:"maroon"}}
                className="bg-maroon text-white px-4 py-2 mx-2 my-5 rounded-md shadow-md"
                onClick={fetchReportData}
              >
                Show
              </button>
            </div>
          )}
          -------------------------------
          {pageshow && selectedReport === "yearly" && showGraph && (
            <YearlyReport data={reportData} />
          )}
          {pageshow && selectedReport === "monthly" && showGraph && (
            <MonthlyReport datas={reportData} />
          )}
          {pageshow && selectedReport === "merchantDetails" && showGraph && (
            <MerchantReports data={merchantdailydata} />
          )}
          --------------------------------------------
        </div>
      )}

      {selectedSection === "settlement" && <SellerSettleReports />}
      {selectedSection === "recurring" && <RecurringComponent />}
    </div>
  );
};

const RecurringComponent = () => {
  // Implement your settle ment component here
  return (
    <div>
      <h2>Recurring Component</h2>
      {/* Add your recurring component UI elements here */}
    </div>
  );
};

export default Reportcharting;
