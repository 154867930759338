

// import React, { useState,useEffect } from 'react';
// import { useLocation } from "react-router-dom";
// import { useOktaAuth } from "@okta/okta-react";

// function LinkMail() {
//   //   const location = useLocation();
//   const [response, setResponse] = useState(null);
//     const [verifytok, setVerifytok] = useState(null);
//       const { oktaAuth, authState } = useOktaAuth();

//   // const shortLinkAdd = location.state.responseData || {};
//   // console.log("shortLink", shortLinkAdd);

//   const handleSendRequest = async () => {
//     const formData = new FormData();
//     formData.append('files', document.getElementById('file1').files[0]);
//     formData.append('files', document.getElementById('file2').files[0]);
//     formData.append('toEmail', 'milind@payfi.co.in');
//     formData.append('cc', 'binti@gmail.com');
//     formData.append('subject', 'this is test email');
//     formData.append('body', 'test mail body');
//     formData.append('mobile', '7566328738');
//     formData.append('link', 'http://localhost:8083/transactions/shorturl?uniqueKey=09e2d6f2-abf8-48c5-839e-32d19351478f');
//     formData.append('uniqueKey', '09e2d6f2-abf8-48c5-839e-32d19351478f');

//     try {
//       const response = await fetch('http://localhost:8083/transactions/sendupilink', {
//         method: 'POST',
//         headers: {
//           'Authorization': `Bearer ${verifytok} `,
//         },
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const responseData = await response.json();
//       setResponse(responseData);
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//     useEffect(() => {
//     if (authState && authState.accessToken) {
//       const { accessToken } = authState.accessToken;
//       setVerifytok(accessToken);
//     }
//   });
//   return (
//     <div>
      
//            <div className="mb-4">
//          <label className="block text-gray-700 text-sm font-bold mb-2">
//            Upload Image:
//          </label>
//          <input
//            type="file"
//            name="files"
//            onChange={handleInputChange}
//            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
//          />
//        </div>
//        <div className="mb-4">
//          <label className="block text-gray-700 text-sm font-bold mb-2">
//            Subject:
//          </label>
//          <input
//            type="text"
//            name="subject"
//            value={formData.subject}
//            onChange={handleInputChange}
//            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
//          />
//        </div>

//       <button onClick={handleSendRequest}>Send Request</button>
//       {response && (
//         <div>
//           <h2>Response:</h2>
//           <pre>{JSON.stringify(response, null, 2)}</pre>
//         </div>
//       )}
//     </div>
//   );
// }

// export default LinkMail;

// import React, { useState, useEffect } from 'react';
// import { useLocation } from "react-router-dom";
// import { useOktaAuth } from "@okta/okta-react";
// import { API_GATEWAY } from '../../Apigate/api';

// function LinkMail() {
//     const location = useLocation();
//   const [response, setResponse] = useState(null);
//   const [verifytok, setVerifytok] = useState(null);
//   const { oktaAuth, authState } = useOktaAuth();
//   const [formData, setFormData] = useState({
//     files: [],
//     subject: '',
//     toEmail:'',
//     body:'',
//     mobile:'',
//     cc:'',
//     // uniqueKey:''
//   });

//     const shortLinkAdd = location.state.responseData || {};

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//     const urlString = shortLinkAdd;
//     console.log("urlString",urlString)
//   const extractUniqueKey = (url) => {
//     const paramsStartIndex = url.indexOf("?"); // Find the start index of parameters
//     if (paramsStartIndex !== -1) {
//       // Extract the substring containing parameters
//       const paramsString = url.substring(paramsStartIndex + 1);
//       // Split parameters by '&' and find the uniqueKey parameter
//       const paramsArray = paramsString.split("&");
//       for (const param of paramsArray) {
//         const [key, value] = param.split("=");
//         if (key === "uniqueKey") {
//           return value;
//         }
//       }
//     }
//     return null; // Return null if uniqueKey parameter is not found
//   };

//   const uniqueKey = extractUniqueKey(urlString);
//   console.log(uniqueKey); // Output: 8f312417-970f-4726-b40d-cbafdefd6195


//   const handleSendRequest = async () => {
//     const formDataToSend = new FormData();
//     formDataToSend.append('files', formData.files[0]);
//     formDataToSend.append('toEmail', formData.toEmail);
//     formDataToSend.append('cc', formData.cc);
//     formDataToSend.append('subject', formData.subject);
//     formDataToSend.append('body', formData.body);
//     formDataToSend.append('mobile', formData.mobile);
//     formDataToSend.append('link', shortLinkAdd);
//     formDataToSend.append('uniqueKey', uniqueKey);

//     try {
//       const response = await fetch(`${API_GATEWAY}transactions/sendupilink`, {
//         method: 'POST',
//         headers: {
//           'Authorization': `Bearer ${verifytok} `,
//         },
//         body: formDataToSend,
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const responseData = await response.json();
//       setResponse(responseData);
//       console.log("responseData",responseData)
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   useEffect(() => {
//     if (authState && authState.accessToken) {
//       const { accessToken } = authState.accessToken;
//       setVerifytok(accessToken);
//     }
//   }, [authState]);

//   return (
//     <div>
//       <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">
//           Upload Image:
//         </label>
//         <input
//           type="file"
//           name="files"
//           onChange={(e) => setFormData({ ...formData, files: e.target.files })}
//           className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">
//           Subject:
//         </label>
//         <input
//           type="text"
//           name="subject"
//           value={formData.subject}
//           onChange={handleInputChange}
//           className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">
//           Email:
//         </label>
//         <input
//           type="text"
//           name="toEmail"
//           value={formData.toEmail}
//           onChange={handleInputChange}
//           className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">
//           cc:
//         </label>
//         <input
//           type="text"
//           name="cc"
//           value={formData.cc}
//           onChange={handleInputChange}
//           className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">
//           Mobile:
//         </label>
//         <input
//           type="text"
//           name="mobile"
//           value={formData.mobile}
//           onChange={handleInputChange}
//           className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">
//           body:
//         </label>
//         <input
//           type="text"
//           name="body"
//           value={formData.body}
//           onChange={handleInputChange}
//           className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">
//         uniqueKey:
//         </label>
//         <input
//           type="text"
//           name="uniqueKey"
//           value={uniqueKey}
//           // onChange={handleInputChange}
//           className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">
//         uniqueKey:
//         </label>
//         <input
//           type="text"
//           name="uniqueKey"
//           value={shortLinkAdd}
//           // onChange={handleInputChange}
//           className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
//         />
//       </div>

//       <button onClick={handleSendRequest}>Send Request</button>
//       {response && (
//         <div>
//           <h2>Response:</h2>
//           <pre>{JSON.stringify(response, null, 2)}</pre>
//         </div>
//       )}
//     </div>
//   );
// }

// export default LinkMail;

import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { API_GATEWAY } from '../../Apigate/api';

function LinkMail() {
  const location = useLocation();
  const [response, setResponse] = useState(null);
  const [verifytok, setVerifytok] = useState(null);
  const { oktaAuth, authState } = useOktaAuth();
  const [formData, setFormData] = useState({
    files: [],
    subject: '',
    toEmail: '',
    body: '',
    mobile: '',
    cc: '',
  });

  const shortLinkAdd = location.state?.responseData || '';

  useEffect(() => {
    if (authState && authState.accessToken) {
      setVerifytok(authState.accessToken);
    }
  }, [authState]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'files') {
      setFormData({ ...formData, files: files });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const extractUniqueKey = (url) => {
    const paramsStartIndex = url.indexOf("?");
    if (paramsStartIndex !== -1) {
      const paramsString = url.substring(paramsStartIndex + 1);
      const paramsArray = paramsString.split("&");
      for (const param of paramsArray) {
        const [key, value] = param.split("=");
        if (key === "uniqueKey") {
          return value;
        }
      }
    }
    return null;
  };

  const uniqueKey = extractUniqueKey(shortLinkAdd);

  const handleSendRequest = async () => {
    const formDataToSend = new FormData();
    if (formData.files.length > 0) {
      Array.from(formData.files).forEach(file => formDataToSend.append('files', file));
    }
    formDataToSend.append('toEmail', formData.toEmail);
    formDataToSend.append('cc', formData.cc);
    formDataToSend.append('subject', formData.subject);
    formDataToSend.append('body', formData.body);
    formDataToSend.append('mobile', formData.mobile);
    formDataToSend.append('link', shortLinkAdd);
    formDataToSend.append('uniqueKey', uniqueKey);

    try {
      const response = await fetch(`${API_GATEWAY}transactions/sendupilink`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${verifytok}`,
        },
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      setResponse(responseData);
      console.log("responseData", responseData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Upload Image:
        </label>
        <input
          type="file"
          name="files"
          onChange={handleInputChange}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          multiple
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Subject:
        </label>
        <input
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Email:
        </label>
        <input
          type="text"
          name="toEmail"
          value={formData.toEmail}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          cc:
        </label>
        <input
          type="text"
          name="cc"
          value={formData.cc}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Mobile:
        </label>
        <input
          type="text"
          name="mobile"
          value={formData.mobile}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Body:
        </label>
        <input
          type="text"
          name="body"
          value={formData.body}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Unique Key:
        </label>
        <input
          type="text"
          name="uniqueKey"
          value={uniqueKey || ''}
          readOnly
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Link:
        </label>
        <input
          type="text"
          name="shortLinkAdd"
          value={shortLinkAdd || ''}
          readOnly
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>
      <button onClick={handleSendRequest} className="bg-blue-500 text-white px-4 py-2 rounded">
        Send Request
      </button>
      {response && (
        <div>
          <h2>Response:</h2>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default LinkMail;
