import React from "react";
import Nav from "../Navbar";

const PaymentStatusCheck = () => {
  return (
    <div>
      <Nav />
      <div>
        <div className="flex justify-center items-center py-4">
          <h1 className="text-2xl">Payment Statement Check</h1>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatusCheck;
