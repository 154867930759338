import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import ExtractEncodedPayload from '../../utils/ExtractEncodedPayload';
import { API_GATEWAY } from '../../Apigate/api';


const TransactionComponent = () => {
  const [urllinkpayload, setUrllink] = useState(null);
  // const [customerVpa,setCustomerVpa] = useState("")
  const location = useLocation();
  const { oktaAuth, authState } = useOktaAuth();
  const [verifytok, setVerifytok] = useState("");

  // Extract the uniqueKey from the URL
  const queryParams = new URLSearchParams(location.search);
  const uniqueKey = queryParams.get('uniqueKey');
  // const url = `http://localhost:8083/transactions/shorturl?uniqueKey=${uniqueKey}`;
  const url = API_GATEWAY + `transactions/shorturl?uniqueKey=${uniqueKey}`;
  // const url = API_GATEWAY + `transactions/shorturl?uniqueKey=${uniqueKey}`;

  console.log("ExtractEncodedPayload",ExtractEncodedPayload)
  const extractUniqueKey = (urllinkpayload) => {
    if (urllinkpayload) {
      const paramsStartIndex = url.indexOf("?");
      if (paramsStartIndex !== -1) {
        const paramsString = urllinkpayload.substring(paramsStartIndex + 1);
        const paramsArray = paramsString.split("&");
        for (const param of paramsArray) {
          const [key, value] = param.split("=");
          if (key === "uniqueKey") {
            return value;
          }
        }
      }
    }
    return null;
  };

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${verifytok}`,
            'Content-Type': 'application/json'
          }
        });
        const result = await response.json();
        setUrllink(result.originalLink);
        console.log("payloadoriginal",result.originalLink)
        // const extractedData = ExtractEncodedPayload(result.originalLink);
        // console.log("ExtractEncodedPayload", extractedData);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (uniqueKey) {
      fetchData();
    }
    setTimeout(() => {
      console.log("urllinkpayload",urllinkpayload)
    },7000)
  }, [url, verifytok, uniqueKey,urllinkpayload]);

    

  useEffect(() => {
    if (authState && authState.accessToken) {
      const { accessToken } = authState;
      setVerifytok(accessToken.accessToken);
    }
  }, [authState]);

  return (
    <div>
      {urllinkpayload ? (
        <div> 
          <ExtractEncodedPayload urllinkpayload={urllinkpayload} />
          <div className="border border-black p-4">
          {/* <label className="block font-semibold mb-2" htmlFor="customerVpa">
            Customer VPA:
          </label> */}
          {/* <input
            className="border border-black p-2 w-full"
            type="text"
            id="customerVpa"
            placeholder="Enter customer VPA"
            value={customerVpa}
            onChange={(e) => setCustomerVpa(e.target.value)}
          /> */}
        </div>
        {/* <button>submit  </button> */}
        </div>
        
      ) : (
        <p>Loading...</p>
      )}
      {/* <a href={data}>original_link</a> */}
    </div>
  );
};

export default TransactionComponent;
