
import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import paynitelogo from "../../assets/images/paynitelogo.png";
import { useOktaAuth } from "@okta/okta-react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_GATEWAY } from "../../Apigate/api";
import { useLocation } from "react-router-dom";

const PaymentEmail = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [verifytok, setVerifytok] = useState(null);
  const [merchantData, setMerchantData] = useState([]);
  const [shortLink, setShortLink] = useState("");
  const [formData, setFormData] = useState({
    amount: "",
  });
  // console.log("formdata--1111", formData);
  const { merchantId } = useParams();
  const [showPopUp, setShowPopUp] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  console.log("location",location.state)
  const searchParams = new URLSearchParams(location.search);
  const planName = searchParams.get("plan");
  //-------
  const [file, setFile] = useState(null);
  const [cc, setCc] = useState("");
  const [toEmail, setToEmail] = useState("");
  const [uniqueKey, setUniqueKey] = useState("");

  const [link, setLink] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [mobile, setMobile] = useState("");
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  // const [amount, setAmount] = useState("8708832520");
  //-------
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

  useEffect(() => {
    // console.log("111111111111", merchantData);
    if (merchantData && merchantData.merchantOktaId) {
      setFormData((prevData) => ({
        ...prevData,
        merchantOktaId: merchantData.merchantOktaId,
        merchantId: merchantData.merchantId,
        adminEmailId: "payfiadmin@payfi.co.in",
        targetAccountId: 87399435,
        targetOwnerName: "Payfi",
        amount: "",
        currency: "INR",
        message: "payment for goods",
        latitude: 12.12,
        longitude: 13.74,
        partnerReferenceNumber: 34788441,
        customerVpa: "",
        status: "pending",
        initiationDate: "2024-04-19T10:15:30",
        completionDate: "2024-04-20T10:15:30",
      }));
    }
  }, [merchantData]);

  useEffect(() => {
    if (authState && authState.accessToken) {
      const { accessToken } = authState.accessToken;
      setVerifytok(accessToken);
    }
  });

  const fetchMerchantDetails = async () => {
    try {
      const response = await axios.get(
        API_GATEWAY + "merchants/getalldetailsofmerchant",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${verifytok}`,
          },
        }
      );

      const data = response;
      setMerchantData(data.data.merchantBasicDetails);
      // console.log("response", merchantData.merchantId);
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  useEffect(() => {
    if (verifytok) {
      fetchMerchantDetails();
    }
  }, [verifytok, merchantId]);

  useEffect(() => {
    if (verifytok) {
      fetchMerchantDetails();
    }
  }, [verifytok, merchantId]);

  // ------------------------fdfdfdfdfdfdfdfdfdf

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("cc", cc);
      formData.append("toEmail", toEmail);
      formData.append("uniqueKey", uniqueKey);
      formData.append("link", location.state.shortLink);
      formData.append("subject", subject);
      formData.append("body", body);
      formData.append("mobile", mobile);

      const response = await axios.post(
        API_GATEWAY + "transactions/sendupilink",
        
        // "http://localhost:8083/transactions/sendupilink",
        formData,
        {
          headers: {
            Authorization: `Bearer ${verifytok}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setResponse(response.data);
      console.log("Response data:", response);
      setError("");
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("Transaction not found");
      setResponse(null);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex justify-center">
        {/* <h1 className="text-3xl text-[#6a5acd] font-bold pt-1 -mb-6">
          Via Payment Link
        </h1> */}
        <ToastContainer />
      </div>
      <div className="flex flex-col items-center">
        <div className="flex py-6">
          <table className="m-3  border-collapse">
            <thead>
              <tr>
                <th colSpan="2">
                  <p style={{fontSize:"4vh"}}>Enter Customer Info</p>
                </th>
              </tr>
            </thead>
          </table>
        </div>

        {/* ------------------- */}
        <div>
          <form 
           className="border border-gray-300 rounded-md px-3 py-2"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="flex justify-between mb-2">
              <label className="mr-2 font-bold" htmlFor="file">File:</label>
              <input
              className="border border-gray-300 rounded-md px-3 py-2"
                style={{ marginBottom: "10px", marginLeft: "70px", }}
                type="file"
                id="file"
                name="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
            <div className="flex justify-between mb-2">
              <label className="mr-2 font-bold" htmlFor="cc">CC:</label>
              <input
                            className="border border-gray-300 rounded-md px-3 py-2"

                style={{ marginBottom: "10px", marginLeft: "70px"  }}
                type="text"
                id="cc"
                name="cc"
                placeholder="CC"
                value={cc}
                onChange={(e) => setCc(e.target.value)}
              />
            </div>
            <div className="flex justify-between mb-2">
              <label className="mr-2 font-bold" htmlFor="toEmail">To Email:</label>
              <input
                            className="border border-gray-300 rounded-md px-3 py-2"

                style={{ marginBottom: "10px", marginLeft: "70px"  }}
                type="text"
                id="toEmail"
                name="toEmail"
                placeholder="Email"
                value={toEmail}
                onChange={(e) => setToEmail(e.target.value)}
              />
            </div>
            <div className="flex justify-between mb-2">
              <label className="mr-2 font-bold" htmlFor="subject">Subject:</label>
              <input
                            className="border border-gray-300 rounded-md px-3 py-2"

                style={{ marginBottom: "10px", marginLeft: "70px"  }}
                type="text"
                id="subject"
                name="subject"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div  className="flex justify-between mb-2">
              <label className="mr-2 font-bold" htmlFor="body">Body:</label>
              <input
                            className="border border-gray-300 rounded-md px-3 py-2"

                style={{ marginBottom: "10px", marginLeft: "70px"  }}
                type="text"
                id="body"
                name="body"
                placeholder="Body"
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
            </div>
            <div className="flex justify-between mb-2">
              <label className="mr-2 font-bold" htmlFor="mobile">Mobile:</label>
              <input
                            className="border border-gray-300 rounded-md px-3 py-2"

                style={{ marginBottom: "10px", marginLeft: "70px"  }}
                type="text"
                id="mobile"
                name="mobile"
                placeholder="Mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <button type="submit" className="px-4 py-2 bg-gray-300 hover:bg-blue-500 hover:text-white">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default PaymentEmail;
