import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";

const PaymentStatus = () => {
  const location = useLocation();
  const { paymentCustData } = location.state;


  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/"
    },5000)
  })

  return (
    <div>

      <Navbar />
    {/* <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="border border-black shadow-lg p-6 bg-white text-black hover:shadow-xl hover:border-blue-500">
        <h1 className="text-lg font-bold font-serif mb-4">Payment Status</h1>
        <p>Transaction ID:<span className="ml-2">{paymentCustData.transactionId}</span></p>
        <p>Target Owner Name:<span className="ml-2">{paymentCustData.targetOwnerName}</span></p>
        <p>Admin Email ID:<span className="ml-2">{paymentCustData.adminEmailId}</span></p>
        <p>Amount:<span className="ml-2">{paymentCustData.amount}</span> <span>{paymentCustData.currency}</span></p>
        <p>Message:<span className="ml-2">{paymentCustData.message}</span></p>
        <p>Status:<span className="ml-2">{paymentCustData.status}</span></p>
      </div>
    </div> */}
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
  <div className="border border-gray-300 shadow-lg p-8 bg-white text-gray-800 rounded-lg transition-transform transform hover:shadow-xl hover:border-blue-500 hover:scale-105 w-full max-w-lg mx-4">
    <h1 className="text-xl font-bold font-serif mb-6 text-center text-green-600">Payment Status</h1>
    <div className="space-y-4">
      <p className="flex justify-between"><span className="font-semibold">Transaction ID:</span><span>{paymentCustData.transactionId}</span></p>
      <p className="flex justify-between"><span className="font-semibold">Target Owner Name:</span><span>{paymentCustData.targetOwnerName}</span></p>
      <p className="flex justify-between"><span className="font-semibold">Admin Email ID:</span><span>{paymentCustData.adminEmailId}</span></p>
      <p className="flex justify-between"><span className="font-semibold">Amount:</span><span>{paymentCustData.amount} {paymentCustData.currency}</span></p>
      <p className="flex justify-between"><span className="font-semibold">Message:</span><span>{paymentCustData.message}</span></p>
      <p className="flex justify-between"><span className="font-semibold">Status:</span><span>{paymentCustData.status}</span></p>
    </div>
  </div>
</div>

    </div>
  );
};

export default PaymentStatus;
