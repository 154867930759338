import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';

const TransactionComponent = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const { oktaAuth, authState } = useOktaAuth();
  const [verifytok, setVerifytok] = useState("");

  // Form state
  const [file, setFile] = useState(null);
  const [cc, setCc] = useState("milind@payfi.co.in");
  const [toEmail, setToEmail] = useState("binti@payfi.co.in");
  const [uniqueKey, setUniqueKey] = useState("");
  const [link, setLink] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [mobile, setMobile] = useState("8708832520");
  const [amount, setAmount] = useState("8708832520");
  

  useEffect(() => {
    if (authState && authState.accessToken) {
      const { accessToken } = authState;
      setVerifytok(accessToken.accessToken);
      handleSendRequest(accessToken.accessToken); // Pass the token to the function
    }
  }, [authState]);

  const handleSendRequest = async (token) => {
    try {
      const payload = {
        merchantOktaId: '00ufpaa1jlcQrv0Dy5d7',
        merchantId: 1,
        adminEmailId: 'payfiadmin@payfi.co.in',
        targetAccountId: 87399435,
        targetOwnerName: 'Payfi',
        amount: 100.0,
        currency: 'INR',
        message: 'Payment for goods',
        initiationDate: '2024-04-19T10:15:30',
        completionDate: '2024-04-20T10:15:30',
        transactionType: 'UPILink',
        latitude: 12.34,
        longitude: 56.78,
        partnerReferenceNumber: 34788441,
        customerVpa: '',
        status: 'Pending'
      };

      const response = await axios.post(
        'http://localhost:8083/transactions/upilinkgeneration',
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setResponse(response.data);
      setUniqueKey(extractUniqueKey(response.data));
      setLink(response.data);
      setError('');
    } catch (error) {
      setError('Transaction not found');
      setResponse(null);
    }
  };

  const extractUniqueKey = (url) => {
    if (url) {
      const paramsStartIndex = url.indexOf("?");
      if (paramsStartIndex !== -1) {
        const paramsString = url.substring(paramsStartIndex + 1);
        const paramsArray = paramsString.split("&");
        for (const param of paramsArray) {
          const [key, value] = param.split("=");
          if (key === "uniqueKey") {
            return value;
          }
        }
      }
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('files', file); 
      formData.append('cc', cc);
      formData.append('toEmail', toEmail);
      formData.append('uniqueKey', uniqueKey); 
      formData.append('link', link);
      formData.append('subject', subject); 
      formData.append('body', body); 
      formData.append('mobile', mobile); 

      const response = await axios.post(
        'http://localhost:8083/transactions/sendupilink',
        formData,
        {
          headers: {
            Authorization: `Bearer ${verifytok}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      setResponse(response.data);
      console.log("Response data:", response);
      setError('');
    } catch (error) {
      console.error("Error submitting form:", error);
      setError('Transaction not found');
      setResponse(null);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} encType="multipart/form-data" style={{ display: 'flex', flexDirection: 'column' }}> 
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="file">File:</label>
          <input 
            style={{ marginBottom: '10px', marginLeft: "30px" }} 
            type="file" 
            id="file" 
            name="file" 
            onChange={(e) => setFile(e.target.files[0])} 
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="cc">CC:</label>
          <input 
            style={{ marginBottom: '10px', marginLeft: "30px" }} 
            type="text" 
            id="cc" 
            name="cc" 
            placeholder="CC" 
            value={cc}
            onChange={(e) => setCc(e.target.value)} 
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="toEmail">To Email:</label>
          <input 
            style={{ marginBottom: '10px', marginLeft: "30px" }} 
            type="text" 
            id="toEmail" 
            name="toEmail" 
            placeholder="Email" 
            value={toEmail}
            onChange={(e) => setToEmail(e.target.value)} 
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="uniqueKey">Unique Key:</label>
          <input 
            style={{ marginBottom: '10px', marginLeft: "30px" }} 
            type="text" 
            id="uniqueKey" 
            name="uniqueKey" 
            placeholder="Unique Key" 
            value={uniqueKey}
            onChange={(e) => setUniqueKey(e.target.value)} 
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="link">Link:</label>
          <input 
            style={{ marginBottom: '10px', marginLeft: "30px" }} 
            type="text" 
            id="link" 
            name="link" 
            placeholder="Link" 
            value={link}
            onChange={(e) => setLink(e.target.value)} 
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="subject">Subject:</label>
          <input 
            style={{ marginBottom: '10px', marginLeft: "30px" }} 
            type="text" 
            id="subject" 
            name="subject" 
            placeholder="Subject" 
            value={subject}
            onChange={(e) => setSubject(e.target.value)} 
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="body">Body:</label>
          <input 
            style={{ marginBottom: '10px', marginLeft: "30px" }} 
            type="text" 
            id="body" 
            name="body" 
            placeholder="Body" 
            value={body}
            onChange={(e) => setBody(e.target.value)} 
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="mobile">Mobile:</label>
          <input 
            style={{ marginBottom: '10px', marginLeft: "30px" }} 
            type="text" 
            id="mobile" 
            name="mobile" 
            placeholder="Mobile" 
            value={mobile}
            onChange={(e) => setMobile(e.target.value)} 
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default TransactionComponent;
